.blockImage {
  width: 32px;
  height: 32px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  margin-left: 3px;
  background-color: #222222;
  transition: background-color 0.2s;
}
